import { alert } from '~/utils/alertModals';
import { cartFlyout } from '~/utils/addToCartFlyout';
import cartIconQuantity from '~/state/CartIconQuantity';
import { Buyable } from '~/types/Buyable';

export default async function (
    buyable: Buyable | Buyable[],
    useFlyout = false,
) {
    let addToCartUrl = ``;
    let cartData = {};

    if (Array.isArray(buyable)) {
        addToCartUrl = `products/add-multiple-to-cart`;
        cartData = {
            items: buyable,
        };
    } else {
        addToCartUrl = `products/${buyable.id}/add-to-cart`;
        cartData = {
            quantity: buyable.quantity,
        };
    }

    const addDataToGA4 = (buyable: Buyable | Buyable[]) => {
        if (Array.isArray(buyable)) {
            buyable.forEach((product) => {
                const segmentEvent = useSegmentEventWithCustomPayload(
                    'Product Added',
                    {
                        product_id: product.id,
                        name: product.name,
                        quantity: product.quantity,
                    },
                );

                useHead(() => ({
                    script: segmentEvent,
                }));
            });
        } else {
            const segmentEvent = useSegmentEventWithCustomPayload(
                'Product Added',
                {
                    product_id: buyable.id,
                    name: buyable.name,
                    quantity: buyable.quantity,
                },
            );

            useHead(() => ({
                script: segmentEvent,
            }));
        }
    };

    return await useAuth()
        .apiRequest('POST', addToCartUrl, cartData)
        .then((response: any) => {
            cartIconQuantity.value = response.data.cartItemsCount;
            addDataToGA4(buyable);

            if (useFlyout) {
                let flyoutData = [];
                if (Array.isArray(buyable)) {
                    flyoutData = buyable.map((item) => ({
                        productName: item.name,
                        image: item.image,
                    }));
                } else {
                    flyoutData = [
                        {
                            productName: buyable.name,
                            image: buyable.image,
                        },
                    ];
                }

                cartFlyout(flyoutData);
            }
            return response;
        })
        .catch((error) => {
            alert(
                error.data.data?.heading || 'Error adding product to cart',
                error.data.data.message,
            );
        });
}
