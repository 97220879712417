import { Buyable } from '~/types/Buyable';

const cartFlyout = (buyables: Buyable[]) => {
    const cartFlyout = useNuxtApp().$cartFlyout as (
        buyables: Buyable[],
    ) => void;
    cartFlyout(buyables);
};

export { cartFlyout };
