import { Image } from '~/types/miscellaneous';

interface Buyable {
    id: number;
    quantity?: number;
    name: string;
    image: Image;
}

function createBuyable(item: Buyable): Buyable {
    return { ...item, quantity: item.quantity || 1 };
}

export type { Buyable };
export { createBuyable };
